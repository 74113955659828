@import url('https://fonts.googleapis.com/css2?family=Noto+Naskh+Arabic:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Vazirmatn:wght@100..900&display=swap');
body {
  margin: 0;
  font-family: 'Vazirmatn', serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.zoom-image {
  transition: transform 0.5s ease;
}

.zoom-image:hover {
  transform: scale(2);
}
.carousel-image {
  margin-top: 20px;
  border-radius: 8px;
  width: 100%;
  height: 250px; /* adjust this value as needed */
  object-fit: cover;
}

/* styles.css */

.image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between images */
}

.image-container img {
  flex: 1 1 30%; /* Adjust images to take up 30% of the width */
  height: auto;
}

.thumbnail {
  width: 100%;
  padding-bottom: 75%; /* 4:3 aspect ratio */
  position: relative;
}

.thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Fills container without distortion */
}
